import React from 'react';
import logo from './imgs/growingbrainslogo_white.png';
import Content from './components/Content';
import './App.css';
import "./youtubeStyle.css";
import YoutubeEmbed from "./youtubeEmbed";

function App() {
  return (
    <div className="app">
      <body>
        <div className="logo-div, logo">
            <img src={logo} className="App-logo" alt="Growing Brains" />
        </div>

        <Content />

        <div className="youtubeStyle">
            <YoutubeEmbed embedId="r7C8WvLvi78" />
        </div>

        <div className="copyrightgb">
          <p>Copyright © 2021 <b>Growing Brains LLC</b>. All rights reserved</p>
        </div>
      </body>
    </div>
  );
}

export default App;
