//Content.js
import React from 'react';
import './content.scss';
import "../youtubeStyle.css";

const Content=() => {
  const data=(
    //<div className="box background_gradient_three">
    <div>
        <div className="main-content">
            <h1 className="title-1">Genetic Fluff : Now in Early Access on Steam</h1>
            <h2><a className="link" target="_blank" rel="noopener noreferrer" href="https://store.steampowered.com/app/1403270/Genetic_Fluff/">Genetic Fluff on Steam®</a></h2>
            <h2><a className="link" target="_blank" rel="noopener noreferrer" href="http://geneticfluff.com">GeneticFluff.com</a></h2>

        </div>
    </div>
  );
return (
    <main className="content">
        {data}
    </main>
  )
}
export default Content;